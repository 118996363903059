import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import JsBarcode from "jsbarcode";
import html2pdf from "html2pdf.js";

const CreatePDFInfo = ({ data }) => {
  const barcodeRef = useRef();
  const [barcodeUrl, setBarcodeUrl] = useState("");

  let htmlProductLines = "";

  if (data?.order[0]?.products_lines) {
    data.order[0].products_lines.forEach((item, index) => {
      htmlProductLines += `
        <tr key=${index}>
          <td style="text-align: center; vertical-align: text-top;">${
            index + 1
          }</td>
          <td style="text-align: right;">${item?.name}</td>
          <td style="text-align: center;">${item?.qty}</td>
          <td style="text-align: center;">${item?.price_unit}</td>
          <td style="text-align: center;">${item?.sub_total}</td>
        </tr>
      `;
    });
  }
  let order_id = data?.order[0]?.order_no;
  let order_idChange = order_id.replace(/\s/g, "%20");
  let Change = 0;
  let Cash;

  
  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, order_idChange, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 60,
        displayValue: true,
        fontSize: 16,
      });

      // Convert SVG to DataURL
      const svg = barcodeRef.current;
      const xml = new XMLSerializer().serializeToString(svg);
      const svg64 = btoa(xml);
      const b64Start = "data:image/svg+xml;base64,";
      const image64 = b64Start + svg64;
      setBarcodeUrl(image64);
    }
  }, [order_idChange]);
  console.log("data?.order[0]?.payment_data", data?.order[0]);
  data?.order[0]?.payment_data.forEach((item) => {
    if (item?.name == "return") {
      Change =
        item?.amount < 0
          ? Math.abs(item?.amount).toFixed(1) + "-"
          : item?.amount.toFixed(1);
    }
    if (item?.payment_method == "بنك") {
      Change = 0;
      Cash = item?.amount;
    }
    if (
      item?.name == false &&
      (item?.payment_method == "نقد" || item?.payment_method == "نقدي")
    ) {
      Cash = item?.amount;
    }
    if (
      item?.name == "Delivery" &&
      (item?.payment_method == "نقد" || item?.payment_method == "نقدي")
    ) {
      Cash = item?.amount;
    }
  });

  const htmlContent = `
<!DOCTYPE html>
<html>
  <head>
    <title>POS Receipt</title>
    <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 10px;
      color:"black"
    }
      .pos-top-container{
      display: flex;
      justify-content:center
      }
    .pos-receipt-container {
      margin: 10px;
         width:400px;
    }
    .pos-receipt {
      text-align: center;
      margin-bottom: 20px;
    }
    .pos-receipt-contact {
      margin-bottom: 10px;
      margin-top:5px
    }
    .pos-receipt-contact div{
      font-size: 15px;
    }
    .pos-receipt-contact .cashier {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px dashed black;
    }

    .pos-receipt-contact .cashier div {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .pos-receipt-contact table {
      width: 100%;
      margin-bottom: 10px;
    }

    .pos-receipt-contact table td:first-child {
      width: 50%;
    }

    .receipt-orderdata {
      width: 100%;
      font-size: 15px;
      margin-bottom: 10px;
      margin-top:10px;
      direction:rtl
    }
    .receipt-orderdata th,
    .receipt-orderdata td {
      padding: 6px 0px;
    }
    .receipt-orderdata td {
      font-size: 15px;
    }
    .receipt-orderdata th {
      text-align: center;
      font-weight: bold;
      font-size: 16 px;
    }

    .pos-receipt-right-align {
      float: right;
    }
    .pos-receipt .pos-receipt-right-align {
      float: right;
      font-size: 16px;
    }
    .orderDetail{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      direction: rtl;
    }
    .orderDetail div, .cashierDetail{
      font-size: 15px;
    }
    .receiptHead{
      text-align: center;
      text-align: center;
      font-weight: 600;
      border-top: 1px dashed black;
      padding: 11px 0px 0px;
      font-size: 23px;
      margin: 11px 0px;
    }
    .bottomSection{
      border-top: 1px dashed black;
      margin-top:10px;
      font-size:15px;
      padding:10px;
      text-align:center
    }
    .bottomSection img{
      width:83px;
    }
    .after-footer{
      margin-top:10px;
    }
  </style>
  </head>
  <body>
  <div class="pos-top-container">
    <div class="pos-receipt-container">
      <div class="pos-receipt">
        <div style="text-align: center;">
        <img src="${data.order[0].compan_add.logo}" alt="${
    data.order[0].compan_add.name
  }" width="100">
        </div>
        <div class="pos-receipt-contact">
          <div>${data.order[0].compan_add.name}</div>
          <div>
  ${
    data?.order[0]?.compan_add?.street
      ? data?.order[0]?.compan_add?.street + ", "
      : ""
  }${
    data?.order[0]?.compan_add?.street2
      ? data?.order[0]?.compan_add?.street2 + ", "
      : ""
  }${
    data?.order[0]?.compan_add?.city
      ? data?.order[0]?.compan_add?.city + ", "
      : ""
  }${
    data?.order[0]?.compan_add?.state
      ? data?.order[0]?.compan_add?.state + ", "
      : ""
  }${data?.order[0]?.compan_add?.country}
</div>
        </div>
        <div class="receiptHead">
          ${"إيصال"}
        </div>
        
        <div style="text-align: right;border-top: 1px dashed black;
        border-bottom: 1px dashed black; padding:12px 0px; direction: rtl; ">

        <div class="orderDetail">
        <div class="lines">
         <strong>${("رقم الطلب:")}</strong>
        </div>
        <div class="lines">
        ${data?.order[0]?.order_no}
        </div>
       </div>

       <div class="orderDetail">
       <div class="lines">
       <strong>${("تاريخ الطلب:")}</strong>
       </div>
       <div class="lines">
      
       ${data?.order[0]?.date}
       </div>
      </div>

  <div class="orderDetail">
      <div class="lines">
      <strong>${("اسم الكاشير:")}</strong>
      </div>
      <div class="lines">
      ${data?.order[0]?.cashier}
      </div>
     </div>
         
         
          ${
            data?.order[0]?.customer !== false
              ? `<div class="orderDetail">
               <div class="lines">
                 <strong>${("اسم العميل:")}</strong>
               </div>
               <div class="lines">
                 ${
                   isNaN(parseFloat(data?.order[0]?.customer)) &&
                   !/^\d{3}[-.]?\d{3}[-.]?\d{4}$/.test(data?.order[0]?.customer)
                     ? data?.order[0]?.customer
                     : "--"
                 }
               </div>
             </div>`
              : ""
          }
         
         ${
           data?.order[0]?.customer_mobile !== false
             ? `<div class="orderDetail">
               <div class="lines">
                 <strong>${("رقم  هاتف العميل:")}</strong>
               </div>
               <div class="lines">
                 ${data?.order[0]?.customer_mobile}
               </div>
             </div>`
             : ""
         }
     
            ${
              (data?.order[0]?.order_status === "draft" &&
                data?.order[0]?.customer_address?.length > 0) ||
              (
                data?.order[0]?.customer_address?.length > 0)
                ? `
                 <div class="orderDetail">
                   <div class="lines"><strong>${("العنوان")}:</strong></div>
                   <div class="lines">
                     ${data?.order[0]?.customer_address
                       .map(
                         (address, index) => `
                       <div key=${index}>
                         ${
                           address.add_field_1
                             ? `<span>${
                                 address.name === "Villa"
                                   ? ("رقم الفيلا-")
                                   : address.name === "Apartment"
                                   ? ("المبنى-")
                                   : address.name === "Office"
                                   ? ("الشركة-")
                                   : ""
                               } ${address.add_field_1}</span>, `
                             : ""
                         }
                         ${
                           address.add_field_2
                             ? `<span>${
                                 address.name === "Villa"
                                   ? (" رقم المجموعة-")
                                   : address.name === "Apartment"
                                   ? ("رقم الشقة-")
                                   : address.name === "Office"
                                   ? ("الطابق-")
                                   : ""
                               } ${address.add_field_2}</span>, `
                             : ""
                         }
                         ${
                           address.add_field_3
                             ? `<span>${
                                 address.name === "Villa"
                                   ? ("الشارع-")
                                   : address.name === "Apartment"
                                   ? ("الطابق-")
                                   : address.name === "Office"
                                   ? ("المبنى-")
                                   : ""
                               } ${address.add_field_3}</span>, `
                             : ""
                         }
                         ${
                           address.add_field_4
                             ? `<span>${address.add_field_4}</span>`
                             : ""
                         }
                       </div>
                     `
                       )
                       .join("")}
                   </div>
                 </div>
               `
                : ""
            }
        </div>
        
        <div>
          <table class="receipt-orderdata">
            <colgroup>
              <col width="16%">
              <col width="44%">
              <col width="12%">
              <col width="26%">
              <col width="14%">
            </colgroup>
            <tr>
              <th>${"رقم متسلسل"}</th>
              <th>${"اسم المنتج"}</th>
              <th>${"كمية"}</th>
              <th>${"سعر القطعة"}</th>
              <th>${"الاجمالي"}</th>
            </tr>
            ${htmlProductLines}
          </table>
        </div>
        <div class="orderDetail">
          <div><strong>${"الاجمالي"}</strong></div>
          <div> ${data.order[0].order_value} ج.م</div>
        </div>
        <br>
        <div class="orderDetail">
          <div><strong>${
            data?.order[0]?.payment_data[0]?.payment_method
          }</strong></div>
          <div> ${Cash} ج.م</div>
        </div>
        <br>
        <div class="orderDetail">
          <div><strong>${"فكه"}</strong></div>
          <div>${Change} ج.م</div>
        </div>
        <br>
        <div class="after-footer"></div>
        <div style="display:inline;">
        <img src="${barcodeUrl}" alt="Receipt Barcode" width="300" height="80"/>
        </div>
      </div>
      <div class="bottomSection">
        ${"شريكك التكنولوجي"}<br>
        <img src="myginne.webp" alt="myGinne Logo">
      </div>
    </div>
    </div>
  </body>
</html>
`;
  const receiptRef = useRef();
  function convertImageToBase64(url) {
    // Using AllOrigins to proxy and avoid CORS issues
    const proxyUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(
      url
    )}`;
    return new Promise((resolve, reject) => {
      fetch(proxyUrl)
        .then((response) => response.json())
        .then((data) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            canvas.getContext("2d").drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          img.onerror = () =>
            reject(new Error(`Failed to load image at ${url}`));
          img.src = data.contents; // Using the proxy response
        })
        .catch((error) => reject(error));
    });
  }

  const measureContentHeight = (element) => {
    const clone = element.cloneNode(true);
    clone.style.cssText = `
      position: absolute;
      visibility: hidden;
      width: ${element.offsetWidth}px; // Match the width to ensure layout is the same
    `;
    document.body.appendChild(clone);
    const height = clone.scrollHeight + 100;
    document.body.removeChild(clone);
    return height;
  };

  const downloadPdfDocument = () => {
    const element = receiptRef.current;

    // Assuming you have image URLs directly available
    const imageURL = data.order[0].compan_add.logo;

    convertImageToBase64(imageURL)
      .then((base64Image) => {
        element.querySelectorAll("img")[0].src = base64Image; // Replace the src of the first image, adjust selector as needed
        const contentHeight = measureContentHeight(element);
        const mmPerPixel = 0.2645833333; // Conversion factor from px to mm (assuming 96 DPI)
        const pdfHeight = contentHeight * mmPerPixel;
        const options = {
          margin: [1, 1, 1, 1],
          filename: "order_receipt.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            logging: true,
            dpi: 192,
            letterRendering: true,
            useCORS: true,
            allowTaint: false,
          },
          jsPDF: {
            unit: "mm",
            format: [110, pdfHeight],
            putOnlyUsedFonts: true,
            compress: true,
            orientation: "portrait",
            pagesplit: false, // Ensure no automatic page split
          },
        };

        html2pdf().set(options).from(element).save();
      })
      .catch((error) => {
        console.error("Error converting images:", error);
      });
  };

  return (
    <div className="container">
      <button className="downloadButton" onClick={downloadPdfDocument}>
        <FontAwesomeIcon icon={faDownload} />
      </button>
      <div ref={receiptRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <div style={{ display: "none" }}>
        <svg ref={barcodeRef}></svg>
      </div>
    </div>
  );
};
export default CreatePDFInfo;
